<template>
  <div>
      <DxDropDownBox
          ref="dropDownBoxRefName"
          :drop-down-options="dropDownOptions"
          :data-source="getProductionInfo"
          :value.sync="currentValue"
          display-expr="품번"
          value-expr="품번"
          content-template="contentTemplate"
          :disabled='getEdit'
      >
        <template #contentTemplate="{}">
          <dx-data-grid ref='refProGrid'
            :data-source='getProductionInfo'
            :show-borders = 'true' :showRowLines = 'false' :showColumnLines='true'
            :focused-row-enabled="true"
            @focused-row-changed="onSelectionChanged"
            :focused-row-key.sync='focusedRowKey'
            :auto-navigate-to-focused-row="false"
            key-expr="품번"
            :hover-state-enabled = "true" :allowColumnResizing = 'true'
            column-resizing-mode = 'widget' :allow-column-reordering = "true"
            :height='gridHeight'
          >
            <DxSearchPanel
              :visible="true"
              placeholder="검색..."
            />
            <DxFilterRow
              :visible="true"
              apply-filter="auto"
            />
            <DxColumn
              :allowEditing='false'
              caption="차종"
              data-field="차종"
              :visible='true'
            >
              <DxLookup
                :data-source="차종리스트"
                display-expr="차종"
                value-expr="차종"
              />
            </DxColumn>
            <DxColumn
              :allowEditing='false'
              caption="자재유형"
              data-field="자재유형"
              :visible='true'
              width='120'
            >
              <DxLookup
                :data-source="itemTypeLookup"
                display-expr="코드명"
                value-expr="코드"
              />
            </DxColumn>
            <DxColumn
              caption="품번"
              data-field="품번"
              sort-order='asc'
              width = '120'>
            </DxColumn>
            <DxColumn
              caption="품명"
              data-field="품명">
            </DxColumn>
            <dx-paging :enabled="false"/>
            <dx-scrolling mode="virtual" showScrollbar='always' :useNative='false'/>
            <dx-sorting mode="multiple"/>
          </dx-data-grid>
        </template>
      </DxDropDownBox>
  </div>
</template>

<script>
import DxDataGrid, {
  DxScrolling, DxLookup, DxFilterRow,
  DxColumn, DxPaging, DxSorting, DxSearchPanel
} from 'devextreme-vue/data-grid'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import DxDropDownBox from 'devextreme-vue/drop-down-box'
export default {
  name: 'ProductionGridComponent',
  data: function () {
    return {
      currentValue: this.value,
      dropDownOptions: { width: 430 },
      dataSource: null,
      gridHeight: 300,
      itemTypeLookup: null,
      focusedRowKey: null,
      차종리스트: []
    }
  },
  props: {
    option: { // display 품명 or 품번
      type: String,
      default: '품명'
    },
    value: {
      type: String,
      default: null
    },
    productionInfo: {
      type: Array,
      default: null
    },
    isEdit: {
      type: Boolean,
      default: true
    },
    onValueChanged: {
      type: Function,
      default: () => function (data) {
        // console.log(data)
      }
    }
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSorting,
    DxSearchPanel,
    DxScrolling,
    DxDropDownBox,
    DxLookup,
    DxFilterRow
  },
  computed: {
    getProductionInfo () {
      this.gByCarType(this.productionInfo)
      return this.productionInfo
    },
    getEdit () {
      // console.log(this.isEdit)
      return !this.isEdit
    }
  },
  created () {
    this.itemTypeLookup = this.$store.getters.getBaseDataByType(ConstDef.자재유형)
    if (this.itemTypeLookup === null || this.itemTypeLookup === undefined || this.itemTypeLookup.length === 0) {
      this.$_sp.runNoEncodeFindSqlProc('공통코드', { 상위코드: ConstDef.자재유형 })
        .then((data) => {
          this.itemTypeLookup = this.$_sp.MakeModel(data)
        })
    }
  },
  mounted () {

  },
  updated () {
  },
  beforeDestroy: function () {

  },
  methods: {
    initData () {
      this.currentValue = null
      this.focusedRowKey = null
      this.$refs.dropDownBoxRefName.instance.open()
    },
    onSelectionChanged (e) {
      this.currentValue = e.row.data[this.option]
      this.onValueChanged(e.row.data[this.option])
      if (this.option === '품명') {
        this.$emit('selected-pcode', e.row.data.품번)
      } else {
        this.$emit('selected-pcode', e.row.data.품번)
      }
      this.$refs.dropDownBoxRefName.instance.close()
    },
    gByCarType (productionInfo) {
      const gByCarType = this.$_.groupBy(productionInfo, function (carList) { return carList.차종 })
      const array = Object.keys(gByCarType)
      for (var i in array) {
        this.차종리스트.push({ 차종: array[i] })
      }
    }
  }
}
</script>

<style lang="scss">

</style>
