<template>
  <div class='sub-page'>
    <div class='sub-tab-wrap'>
      <DxTabPanel
          id="tabpanel"
          :selected-index.sync="selectedIndex"
          :data-source='tabs'
          :focusStateEnabled='true'
          @titleClick='onItemClick'
        >
        <template #title="{ data: tab }">
        <div class='sub-title-wrap-tab'>
          <ul>
            <li class='float-left sub-title'>{{tab.title}}</li>
          </ul>
          </div>
        </template>
      </DxTabPanel>
    </div>
    <DayDeliveryListComponent ref='refDayDeliveryListComponent' v-if="selectedIndex === 0"/>
    <MonthDeliveryListComponent ref='refMonthDeliveryListComponent' v-if="selectedIndex === 1"/>
  </div>
</template>

<script>
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import DxTabPanel from 'devextreme-vue/tab-panel'
import DayDeliveryListComponent from './DeliveryListTab/DayDeliveryListComponent'
import MonthDeliveryListComponent from './DeliveryListTab/MonthDeliveryListComponent'

export default {
  name: 'bom-tab',
  data: function () {
    return {
      selectedIndex: 0,
      displayPurchase: 'block',
      displayProduct: 'none',
      selectedCode: 'ALL',
      tabs: [
        {
          title: '거래명세표발행'
        },
        {
          title: '거래내역조회'
        }
      ]
    }
  },
  components: {
    DxTabPanel,
    DayDeliveryListComponent,
    MonthDeliveryListComponent
  },
  created () {
    document.title = ConstDef.TAB_TITLE
    const vendorInfo = this.$store.getters.getVendorInfo
    if (vendorInfo === undefined || vendorInfo === null) {
      this.$router.push('/')
    }
    if (this.$route.query.type === 'product') {
      this.selectedIndex = 1
    }
    const topMenuTab = this.$store.getters.getTopMenuTabByUrl(this.$route.path)
    // console.log(topMenuTab)
    if (topMenuTab !== null) {
      const tabNameArray = topMenuTab.multiTabName.split('/')
      this.tabs = []
      for (let i = 0; i < tabNameArray.length; i++) {
        const param = { title: tabNameArray[i] }
        this.tabs.push(param)
      }
    }
  },
  mounted () {

  },
  updated () {
  },
  beforeDestroy: function () {

  },
  methods: {
    onItemClick (e) {
      // console.log(this.tabs[e.itemIndex].code)
      // console.log(this.selectedIndex)
      if (this.tabs[e.itemIndex].code === 'ALL') {
        return
      }
      this.selectedCode = this.tabs[e.itemIndex].code
      if (this.selectedCode === ConstDef.구매품 || this.selectedCode === ConstDef.완제품) {
        return
      }
      this.$refs.refSemiProductComponent.refreshProduct(this.selectedCode)
    }
  }
}
</script>

<style lang="scss">
  .purchaseActive {
    display: none;
  }
  .productActive {
    display: none;
  }

</style>
