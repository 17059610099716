<template>
    <div class='sub-content'> <!-- 여기까지 page header 이하 수정해서 사용-->
      <div class='grid-wrap'>
        <dx-data-grid ref='refDeliveryListGrid'
          :data-source='deliveryList'
          :show-borders = 'true' :showRowLines = 'false' :showColumnLines='true'
          :hover-state-enabled = "true" :allowColumnResizing = 'true'
          column-resizing-mode = 'widget' :allow-column-reordering = "true"
          :height='gridHeight' :row-alternation-enabled="true"
          :focused-row-enabled="true"
          @focused-row-changed="onSelectionChanged"
          :focused-row-key.sync='focusedRowKey'
          :auto-navigate-to-focused-row="false"
          key-expr="seqId"
          @toolbar-preparing='onToolbarPreparing'
          @row-inserted='onRowInserted' @row-inserting='onRowInserting'
          @row-updated='onRowUpdated' @row-updating="onRowUpdating"
          @row-removed='onRowRemoved'
          @editor-preparing='onEditorPreparing'
          @cell-prepared='onCellPreparedOutput'
          @init-new-row='onInitNewRow'
        >
          <DxSearchPanel
            :visible="true"
            placeholder="검색..."
          />
          <DxExport
            :enabled="true"
            :file-name="exportTitle"
          />
          <DxFilterRow
            :visible="true"
            apply-filter="auto"
          />
          <DxEditing
            :allow-deleting="true"
            :allow-updating="true"
            :allow-adding="false"
            :use-icons="true"
            mode='popup'
          >
            <DxPopup
              :show-title="true"
              :width="600"
              :height="200"
              title="거래내역서"
            >
              <dx-position
                my="center"
                at="center"
                of="window"
              />
            </DxPopup>
            <DxForm>
              <DxItem
                :col-count="2"
                :col-span="2"
                item-type="group"
              >
                <DxItem data-field="납품일자"/>
                <DxItem data-field="생산일자"/>
                <DxItem data-field="품번"/>
                <DxItem data-field="납품수량"/>
              </DxItem>
            </DxForm>
          </DxEditing>
          <DxColumnFixing :enabled="true"/>
          <DxColumn
            caption="거래처코드"
            data-field="거래처코드"
            >
          </DxColumn>
          <DxColumn
            caption="거래처명"
            data-field="거래처명"
            :visible='true'
          >
          </DxColumn>
          <DxColumn
            caption="납품일자"
            data-field="납품일자"
            dataType='date'
            format='yyyy-MM-dd'>
            <dx-required-rule message="항목을 입력하세요"/>
          </DxColumn>
          <DxColumn
            caption="생산일자"
            data-field="생산일자"
            dataType='date'
            format='yyyy-MM-dd'>
          </DxColumn>
          <DxColumn
            caption="차종"
            data-field="차종">
          </DxColumn>
          <DxColumn
            caption="품번"
            data-field="품번"
            :visible='true'
            edit-cell-template="productionGridTemplate"
          >
            <dx-required-rule message="항목을 입력하세요"/>
          </DxColumn>
          <DxColumn
            caption="품명"
            data-field="품명"
            :visible='true'
          >
          </DxColumn>
          <DxColumn
            :allowEditing='isIdEdit'
            caption="납품수량"
            data-field="납품수량"
            :visible='true'
            dataType='number'
          >
            <dx-required-rule message="항목을 입력하세요"/>
          </DxColumn>
          <DxColumn
            :allowEditing='true'
            caption="실납품수량"
            data-field="실납품수량"
            dataType='number'
            :visible='true'
            format='#,##0'
          >
          </DxColumn>
          <DxColumn
            :allowEditing="false"
            caption="등록일시"
            data-field="createtime"
            dataType='date'
            sort-order='asc'
            format='yyyy-MM-dd HH:mm:ss'>
          </DxColumn>
          <DxColumn
            width="90"
            :buttons="editSubButtons"
            type="buttons"
          />
          <DxSummary>
            <DxTotalItem
              value-format='#,##0'
              column="거래처코드"
              display-format="{0} (건)"
            />
          </DxSummary>
          <dx-paging :enabled="false"/>
          <dx-scrolling mode="virtual" showScrollbar='always' :useNative='false'/>
          <dx-sorting mode="multiple"/>
        </dx-data-grid>
      </div>
      <loading :active.sync="isLoading" color='rgb(2, 110, 156)' loader='bars'></loading>
      <DxPopup2
        :visible.sync="addDelivery"
        :width="600"
        :height="200"
        title="거래내역 등록"
      >
        <div>
          <DxForm2
            ref="refForm"
            id="form"
            :on-content-ready="validateForm"
            :col-count="2"
            :form-data="deliveryData"
          >
            <DxItem
              data-field="납품일자"
              editor-type="dxDateBox"
              :editor-options="{ displayFormat: 'yyyy-MM-dd'}"
            >
              <dx-required-rule message="항목을 입력하세요"/>
            </DxItem>
            <DxItem
              data-field="생산일자"
              editor-type="dxDateBox"
              :editor-options="{ displayFormat: 'yyyy-MM-dd'}"
            />
            <DxItem
              data-field="품번"
              template="productionGridTemplate"
            >
              <dx-required-rule message="항목을 입력하세요"/>
            </DxItem>
            <DxItem
              data-field="납품수량"
              editor-type="dxNumberBox"
              :editor-options="qtyEditorOption"
            >
              <dx-required-rule message="항목을 입력하세요"/>
            </DxItem>
            <DxEmptyItem/>
            <DxEmptyItem/>
            <DxButtonItem
              :col-span="2"
              :button-options="buttonOptions"
              horizontal-alignment="right"
            />
            <template #productionGridTemplate="{ data: cellInfo }">
                <ProductionGridComponent
                  ref="refProductGrid"
                  option="품번"
                  :value="cellInfo.value"
                  :onValueChanged="cellInfo.setValue"
                  @selected-pcode='setProductionCode'
                  :productionInfo='productionList'
                  :isEdit="true"
                />
              </template>
          </DxForm2>
        </div>
      </DxPopup2>
    </div> <!--sub-conten end-->
</template>

<script>
import Loading from 'vue-loading-overlay'
import ConstDef from '../../../../../MesSmartVue/src/share/ConstDef'
import AppLib from '../../../share/AppLib'
import DxDataGrid, { DxScrolling, DxFilterRow, DxColumnFixing, DxTotalItem, DxPosition, DxForm, DxSummary, DxColumn, DxPaging, DxSorting, DxSearchPanel, DxExport, DxEditing, DxPopup } from 'devextreme-vue/data-grid'
import { DxForm as DxForm2, DxItem, DxButtonItem, DxEmptyItem } from 'devextreme-vue/form'
import { DxPopup as DxPopup2 } from 'devextreme-vue/popup'
import { DxRequiredRule } from 'devextreme-vue/validator'
// import DxButton from 'devextreme-vue/button'
import ProductionGridComponent from '../../../share/component/ProductionGridComponent.vue'
import FileService from '../../../share/service/FileService'

export default {
  name: 'DayDeliveryListComponent',
  data: function () {
    return {
      isLoading: false,
      selectedIndex: 0,
      tabs: [
        {
          title: '거래내역서'
        }
      ],
      deliveryList: null,
      vendorInfo: null,
      gridHeight: null,
      focusedRowKey: null,
      exportTitle: null,
      editSubButtons: ['edit', 'delete'],
      isIdEdit: true,
      productionList: null,
      selectedProductionCode: null,
      thisYear: null,
      today: null,
      monthArray: [],
      addDelivery: false,
      납품일자: '납품일자',
      생산일자: '생산일자',
      품번: null,
      납품수량: null,
      deliveryData: null,
      buttonOptions: null,
      validationRules: {
        required: [
          { type: 'required', message: '항목을 입력 하여주세요.' }
        ]
      },
      qtyEditorOption: null
    }
  },
  components: {
    Loading,
    DxDataGrid,
    DxScrolling,
    DxFilterRow,
    DxColumnFixing,
    DxTotalItem,
    DxSummary,
    DxColumn,
    DxPaging,
    DxSorting,
    DxSearchPanel,
    DxExport,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxPosition,
    DxRequiredRule,
    ProductionGridComponent,
    DxForm2,
    DxPopup2,
    DxButtonItem,
    DxEmptyItem
  },
  created () {
    document.title = ConstDef.TAB_TITLE
    this.vendorInfo = this.$store.getters.getVendorInfo
    this.today = AppLib.getToday()
    this.refreshDeliveryList(this.vendorInfo.거래처코드)
    this.refreshProduction()
    this.buttonOptions = {
      text: '저장',
      width: 120,
      type: 'default',
      onClick: (args) => {
        this.onSaveButton()
      }
    }
    this.qtyEditorOption = {
      onEnterKey: (args) => {
        this.onSaveButton()
      }
    }
  },
  mounted () {
    this.$Q('.sub-tab-wrap').css('width', (window.innerWidth - ConstDef.LEFT_MENU_WIDTH - 40) + 'px')
    this.gridHeight = (screen.height - 250) * 0.95
  },
  updated () {
  },
  beforeDestroy: function () {

  },
  methods: {
    initDeliveryData () {
      this.deliveryData = null
      this.deliveryData = {
        납품일자: AppLib.getToday(),
        생성일자: null,
        품번: null,
        납품수량: null
      }
      setTimeout(() => {
        this.$refs.refProductGrid.initData()
      }, 200)
    },
    async onSaveButton () {
      const result = await this.$refs.refForm.instance.validate()
      if (result.isValid === false) {
        return
      }
      this.isLoading = true
      const param = {
        납품일자: this.deliveryData.납품일자,
        생산일자: this.deliveryData.생산일자,
        품번: this.selectedProductionCode,
        납품수량: this.deliveryData.납품수량,
        품명: this.$_.findWhere(this.productionList, { 품번: this.selectedProductionCode }).품명,
        거래처코드: this.vendorInfo.거래처코드
      }
      if (this.deliveryData.납품일자 !== null && (typeof this.deliveryData.납품일자) === 'object') {
        param.납품일자 = this.deliveryData.납품일자.getTime()
      }
      if (this.deliveryData.생산일자 !== null && (typeof this.deliveryData.생산일자) === 'object') {
        param.생산일자 = this.deliveryData.생산일자.getTime()
      } else {
        param.생산일자 = this.deliveryData.납품일자
      }
      param.거래처명 = this.vendorInfo.거래처명
      param.seqId = AppLib.uuidSequential()
      param.updateid = param.createid = this.vendorInfo.아이디
      param.createtime = AppLib.getNow()
      param.차종 = this.$_.findWhere(this.productionList, { 품번: this.selectedProductionCode }).차종
      this.$_sp.runInsertSqlProc('협력사거래내역서', ['품명', '거래처명', '차종'], [param])
        .then((data) => {
          this.isLoading = false
          this.$snotify.info('저장 되었습니다.')
          this.deliveryList.push(param)
          this.$refs.refDeliveryListGrid.instance.refresh()
          // init
          this.initDeliveryData()
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onCellPreparedOutput (e) {
    },
    onDeliveryListDown () {
      if (this.deliveryList.length === 0) {
        this.$snotify.info('금일 거래내역이 없습니다.')
        return
      }
      const 본사명 = this.$_.findWhere(this.$store.getters.getSysParam, { key: '본사명' }).value
      this.isLoading = true
      const param = {
        납품일자: this.today,
        vendorCode: this.vendorInfo.거래처코드,
        본사명: 본사명
      }
      const downFileName = `${this.today}-거래내역서.xlsx`

      FileService.downloadFileWithParam(param, '/CreateDeliveryList', downFileName)
        .then((data) => {
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onEditorPreparing (e) {
    },
    onInitNewRow (row) {
      row.data.납품일자 = this.today
      row.data.거래처코드 = this.vendorInfo.거래처코드
    },
    onRowInserting (row) {
    },
    onRowInserted (row) {
      // console.log(this.deliveryData)
    },
    onRowRemoved (row) {
      this.$_sp.runDeleteSqlProc('협력사거래내역서', [{ seqId: row.data.seqId }])
        .then((data) => {
          this.$snotify.info('삭제 되었습니다.')
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onRowUpdating (row) {

    },
    onRowUpdated (row) {
      row.data.updateid = this.vendorInfo.아이디
      if (row.data.납품일자 !== null && (typeof row.data.납품일자) === 'object') {
        row.data.납품일자 = row.data.납품일자.getTime()
      }
      if (row.data.생산일자 !== null && (typeof row.data.생산일자) === 'object') {
        row.data.생산일자 = row.data.생산일자.getTime()
      }
      if (this.selectedProductionCode !== null && this.selectedProductionCode !== '') {
        row.data.품번 = this.selectedProductionCode
      }
      this.isLoading = true
      this.$_sp.runUpdateSqlProc('협력사거래내역서', ['seqId'], ['품명', '거래처명', '차종'], [row.data])
        .then((data) => {
          this.$snotify.info('수정 되었습니다.')
          this.refreshDeliveryList(this.vendorInfo.거래처코드)
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onSelectionChanged (e) {
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        this.selectedRow = e.row.data
      }
    },
    onToolbarPreparing (e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          template: function () {
            return '<div class="toolbar-label" style="margin-left:4px;color:rgb(4, 103, 189);"><b>납품일자 :  </b></div>'
          }
        },
        {
          location: 'before',
          widget: 'dxDateBox',
          options: {
            width: 94,
            value: this.today,
            type: 'date',
            displayFormat: 'yyyy-MM-dd',
            onValueChanged: (args) => {
              this.today = args.value
              this.refreshDeliveryList(this.vendorInfo.거래처코드)
            }
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            hint: '거래명세출력',
            icon: 'print',
            text: '거래명세표 발행',
            type: 'default',
            focusStateEnabled: true,
            onClick: (args) => {
              this.onDeliveryListDown()
            }
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            hint: '추가',
            icon: 'add',
            focusStateEnabled: true,
            onClick: (args) => {
              this.addDelivery = true
              this.initDeliveryData()
            }
          }
        }
      )
    },
    refreshDeliveryList (거래처코드) {
      this.exportTitle = `${this.today}-거래내역서`
      this.isLoading = true
      const param = {
        납품일자: this.today,
        vendorCode: 거래처코드
      }
      this.$_sp.runNoEncodeFindProc('spFindAllDeliveryListByDayAndVendorCode', param)
        .then((data) => {
          this.isLoading = false
          this.deliveryList = this.$_sp.MakeModel(data)
          console.log(this.deliveryList)
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    refreshProduction () {
      this.$_sp.runNoEncodeFindProc('spFindAllProductionByVendorCode', { vendorCode: this.vendorInfo.거래처코드 })
        .then((data) => {
          this.productionList = this.$_sp.MakeModel(data)
        })
    },
    setProductionCode (code) {
      this.selectedProductionCode = code
      const el = this.$refs.refForm.instance.getEditor('납품수량')._$element[0]
      setTimeout(() => {
        this.$Q(el).find('input:eq(1)').focus()
      }, 200)
    },
    validateForm (e) {
      // e.component.validate()
    }
  }
}
</script>

<style lang="scss">

</style>
