import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import axios from 'axios'

export default class FileService {
  static upload (form, url) {
    const config = {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return axios.post(ConstDef.apiUrl + url, form, config)
      .then((result) => {
        return result.data
      })
  }

  static downloadFile (path, fileName) {
    if (/android/i.test(navigator.userAgent.toLowerCase()) || /ipad|iphone|ipod/.test(navigator.userAgent.toLowerCase())) {
      const link = document.createElement('a')
      link.href = ConstDef.apiUrl + path
      link.setAttribute('download', fileName) // or any other extension
      console.log(fileName)
      document.body.appendChild(link)
      link.click()
    } else {
      return axios.get(ConstDef.apiUrl + path, { responseType: 'blob', withCredentials: true })
        .then((response) => {
          console.log(response)
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName) // or any other extension
          document.body.appendChild(link)
          link.click()
        })
    }
  }

  static downloadFileWithParam (param, path, fileName) {
    if (/android/i.test(navigator.userAgent.toLowerCase())) {
      const link = document.createElement('a')
      link.href = ConstDef.apiUrl + path + '?title=' + param + '&fileName=' + fileName
      link.setAttribute('download', fileName) // or any other extension
      document.body.appendChild(link)
      link.click()
    } else if (/ipad|iphone|ipod/.test(navigator.userAgent.toLowerCase())) {
      const link = document.createElement('a')
      link.href = ConstDef.apiUrl + path + '/' + fileName + '?title=' + param
      link.setAttribute('download', fileName) // or any other extension
      document.body.appendChild(link)
      link.click()
    } else {
      const json = { params: param }
      // console.log(json)
      return axios.get(ConstDef.apiUrl + path, { params: json, responseType: 'blob', withCredentials: true })
        .then((response) => {
          console.log(response)
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName) // or any other extension
          document.body.appendChild(link)
          link.click()
        })
    }
  }

  static downloadPostFileWithParam (param, path, fileName) {
    return axios.post(ConstDef.apiUrl + path, param, { responseType: 'blob', withCredentials: true })
      .then((response) => {
        console.log(response)
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
  }

  static getImgLoad (url) {
    return axios.get(url, { responseType: 'blob', withCredentials: true })
      .then((result) => {
        return result.data
      })
  }
}
