import { render, staticRenderFns } from "./MonthDeliveryListComponent.vue?vue&type=template&id=006f8635&"
import script from "./MonthDeliveryListComponent.vue?vue&type=script&lang=js&"
export * from "./MonthDeliveryListComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports