<template>
    <div class='sub-content'> <!-- 여기까지 page header 이하 수정해서 사용-->
      <div class='grid-wrap'>
        <dx-data-grid ref='refDeliveryListGrid'
          :data-source='deliveryList'
          :show-borders = 'true' :showRowLines = 'false' :showColumnLines='true'
          :hover-state-enabled = "true" :allowColumnResizing = 'true'
          column-resizing-mode = 'widget' :allow-column-reordering = "true"
          :height='gridHeight' :row-alternation-enabled="true"
          :focused-row-enabled="true"
          @focused-row-changed="onSelectionChanged"
          :focused-row-key.sync='focusedRowKey'
          :auto-navigate-to-focused-row="false"
          key-expr="seqId"
          @toolbar-preparing='onToolbarPreparing'
          @row-inserted='onRowInserted' @row-inserting='onRowInserting'
          @row-updated='onRowUpdated' @row-updating="onRowUpdating"
          @row-removed='onRowRemoved'
          @editor-preparing='onEditorPreparing'
          @cell-prepared='onCellPreparedOutput'
          @init-new-row='onInitNewRow'
        >
          <DxSearchPanel
            :visible="true"
            placeholder="검색..."
          />
          <DxExport
            :enabled="true"
            :file-name="exportTitle"
          />
          <DxFilterRow
            :visible="true"
            apply-filter="auto"
          />
          <DxColumn
            caption="거래처코드"
            data-field="거래처코드"
            >
          </DxColumn>
          <DxColumn
            caption="거래처명"
            data-field="거래처명"
            :visible='true'
          >
          </DxColumn>
          <DxColumn
            caption="납품일자"
            data-field="납품일자"
            dataType='date'
            format='yyyy-MM-dd'>
          </DxColumn>
          <DxColumn
            caption="생산일자"
            data-field="생산일자"
            dataType='date'
            format='yyyy-MM-dd'>
          </DxColumn>
          <DxColumn
            caption="차종"
            data-field="차종">
          </DxColumn>
          <DxColumn
            caption="품번"
            data-field="품번"
            :visible='true'
            edit-cell-template="productionGridTemplate"
          >
          </DxColumn>
          <DxColumn
            caption="품명"
            data-field="품명"
            :visible='true'
          >
          </DxColumn>
          <DxColumn
            caption="납품수량"
            data-field="납품수량"
            :visible='true'
            dataType='number'
          >
          </DxColumn>
          <DxColumn
            caption="실납품수량"
            data-field="실납품수량"
            dataType='number'
            :visible='true'
            format='#,##0'
          >
          </DxColumn>
          <DxColumn
            :allowEditing="false"
            caption="등록일시"
            data-field="createtime"
            dataType='date'
            sort-order='asc'
            format='yyyy-MM-dd HH:mm:ss'>
          </DxColumn>
          <DxSummary>
            <DxTotalItem
              value-format='#,##0'
              column="거래처코드"
              display-format="{0} (건)"
            />
          </DxSummary>
          <dx-paging :enabled="false"/>
          <dx-scrolling mode="virtual" showScrollbar='always' :useNative='false'/>
          <dx-sorting mode="multiple"/>
          <template #productionGridTemplate="{ data: cellInfo }">
            <ProductionGridComponent
              option="품번"
              :value="cellInfo.value"
              :onValueChanged="cellInfo.setValue"
              @selected-pcode='setProductionCode'
              :productionInfo='productionList'
              :isEdit="true"
            />
          </template>
        </dx-data-grid>
      </div>
      <loading :active.sync="isLoading" color='rgb(2, 110, 156)' loader='bars'></loading>
    </div> <!--sub-conten end-->
</template>

<script>
import Loading from 'vue-loading-overlay'
import ConstDef from '../../../../../MesSmartVue/src/share/ConstDef'
import AppLib from '../../../share/AppLib'
import DxDataGrid, { DxScrolling, DxFilterRow, DxTotalItem, DxSummary, DxColumn, DxPaging, DxSorting, DxSearchPanel, DxExport } from 'devextreme-vue/data-grid'
import ProductionGridComponent from '../../../share/component/ProductionGridComponent.vue'

export default {
  name: 'MonthDeliveryListComponent',
  data: function () {
    return {
      isLoading: false,
      selectedIndex: 0,
      tabs: [
        {
          title: '거래내역서'
        }
      ],
      deliveryList: null,
      vendorInfo: null,
      gridHeight: null,
      focusedRowKey: null,
      exportTitle: null,
      editSubButtons: ['edit', 'delete'],
      isIdEdit: true,
      productionList: null,
      selectedProductionCode: null,
      thisYear: null,
      yearArray: [],
      thisMonth: null,
      monthArray: []
    }
  },
  components: {
    Loading,
    DxDataGrid,
    DxScrolling,
    DxFilterRow,
    DxTotalItem,
    DxSummary,
    DxColumn,
    DxPaging,
    DxSorting,
    DxSearchPanel,
    DxExport,
    ProductionGridComponent
  },
  created () {
    document.title = ConstDef.TAB_TITLE
    this.vendorInfo = this.$store.getters.getVendorInfo
    this.thisYear = AppLib.getThisYear()
    for (let i = this.thisYear; i >= 2021; i--) {
      this.yearArray.push({ year: i })
    }
    this.thisMonth = AppLib.getThisMonth()
    for (let i = 1; i <= 12; i++) {
      if (i > 0 && i < 10) {
        this.monthArray.push({ name: i + '월', value: '0' + i })
      } else {
        this.monthArray.push({ name: i + '월', value: '' + i })
      }
    }
    this.refreshDeliveryList(this.vendorInfo.거래처코드)
    this.refreshProduction()
  },
  mounted () {
    this.$Q('.sub-tab-wrap').css('width', (window.innerWidth - ConstDef.LEFT_MENU_WIDTH - 40) + 'px')
    this.gridHeight = (screen.height - 250) * 0.95
  },
  updated () {
  },
  beforeDestroy: function () {

  },
  methods: {
    onCellPreparedOutput (e) {
    },
    onEditorPreparing (e) {
    },
    onInitNewRow (row) {
      row.data.거래처코드 = this.vendorInfo.거래처코드
    },
    onRowInserting (row) {
    },
    onRowInserted (row) {
      row.data.updateid = row.data.createid = this.vendorInfo.이메일
      row.data.품번 = this.selectedProductionCode
      if (row.data.납품일자 !== null && (typeof row.data.납품일자) === 'object') {
        row.data.납품일자 = row.data.납품일자.getTime()
      }
      this.isLoading = true
      console.log(row)
      this.$_sp.runInsertSqlProc('협력사거래내역서', ['seqId', '품명', '차종'], [row.data])
        .then((data) => {
          this.isLoading = false
          this.refreshDeliveryList(this.vendorInfo.거래처코드)
          this.$snotify.info('저장 되었습니다.')
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onRowRemoved (row) {
      this.$_sp.runDeleteSqlProc('협력사거래내역서', [{ seqId: row.data.seqId }])
        .then((data) => {
          this.$snotify.info('삭제 되었습니다.')
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onRowUpdating (row) {

    },
    onRowUpdated (row) {
      row.data.updateid = this.vendorInfo.이메일
      if (row.data.납품일자 !== null && (typeof row.data.납품일자) === 'object') {
        row.data.납품일자 = row.data.납품일자.getTime()
      }
      if (row.data.생산일자 !== null && (typeof row.data.생산일자) === 'object') {
        row.data.생산일자 = row.data.생산일자.getTime()
      }
      if (this.selectedProductionCode !== null && this.selectedProductionCode !== '') {
        row.data.품번 = this.selectedProductionCode
      }
      this.isLoading = true
      this.$_sp.runUpdateSqlProc('협력사거래내역서', ['seqId'], ['품명', '거래처명'], [row.data])
        .then((data) => {
          this.$snotify.info('수정 되었습니다.')
          this.refreshDeliveryList(this.vendorInfo.거래처코드)
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onSelectionChanged (e) {
      if (e != null && e.row === undefined) {
        return
      }
      if (e !== null) {
        this.selectedRow = e.row.data
      }
    },
    onToolbarPreparing (e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          template: function () {
            return '<div class="toolbar-label" style="margin-left:4px;color:rgb(4, 103, 189);"><b>연도 :  </b></div>'
          }
        },
        {
          location: 'before',
          widget: 'dxSelectBox',
          options: {
            width: 80,
            items: this.yearArray,
            valueExpr: 'year',
            displayExpr: 'year',
            value: this.thisYear,
            onValueChanged: (args) => {
              this.thisYear = args.value
              this.refreshDeliveryList(this.vendorInfo.거래처코드)
            }
          }
        },
        {
          location: 'before',
          template: function () {
            return '<div class="toolbar-label" style="margin-left:4px;color:rgb(4, 103, 189);"><b>월 :  </b></div>'
          }
        },
        {
          location: 'before',
          widget: 'dxSelectBox',
          options: {
            width: 80,
            items: this.monthArray,
            valueExpr: 'value',
            displayExpr: 'name',
            value: this.thisMonth,
            onValueChanged: (args) => {
              this.thisMonth = args.value
              this.refreshDeliveryList(this.vendorInfo.거래처코드)
            }
          }
        }
      )
    },
    refreshDeliveryList (거래처코드) {
      this.exportTitle = `${this.thisYear}-${this.thisMonth}-거래내역서`
      this.isLoading = true
      const param = {
        startDate: `${this.thisYear}-${this.thisMonth}-01`,
        endDate: `${this.thisYear}-${this.thisMonth}-${AppLib.getMonthLastDay(this.thisYear, this.thisMonth)} 23:59:59`,
        vendorCode: 거래처코드
      }
      this.$_sp.runNoEncodeFindProc('spFindAllDeliveryListByMonthAndVendorCode', param)
        .then((data) => {
          this.isLoading = false
          this.deliveryList = this.$_sp.MakeModel(data)
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    refreshProduction () {
      this.$_sp.runNoEncodeFindProc('spFindAllProductionByVendorCode', { vendorCode: this.vendorInfo.거래처코드 })
        .then((data) => {
          this.productionList = this.$_sp.MakeModel(data)
        })
    },
    setProductionCode (code) {
      this.selectedProductionCode = code
      console.log(this.selectedProductionCode)
    }
  }
}
</script>

<style lang="scss">

</style>
